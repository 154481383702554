import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { Temporal } from '@js-temporal/polyfill';
import { Dropdown } from '../ui/Dropdown';
import Modal from '../ui/Modal';
import Input from '../ui/Input';
import FormItem from '../ui/FormItem';
import { languages } from '../../common/languages';
import { territories } from '../../common/territories';
import { eidrRecordMpmNumber } from '../../common/utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  setMissingEpisode,
  setMissingSeason,
  setSelectedCandidate,
  setSourceCatalog,
  setTitleCandidates
} from '../../features/ingestion/ingestSlice';

import {
  EidrMissingSeasonEpisodeForm,
  SourceCatalog,
  TitleCandidate
} from '../../features/ingestion/types';
import { AddEidrEpisode, AddEidrSeason, EidrRecord } from '../../features/eidr/types';
import { ReferentType } from '../../common/types';
import Button from '../ui/Button';

export interface AddMpmFormProps {
  mpmNumber: string;
}

export default function AddMissingSeasonEpisode(props: {
  open: boolean;
  onClose: () => void;
  setOpen: (value: boolean) => void;
  selectedSeasonEidr?: EidrRecord;
  selectedEpisodeEidr?: EidrRecord;
  selectedRootEidr: EidrRecord;
  mixMode?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { selectedSeasonEidr, selectedEpisodeEidr, selectedRootEidr } = props;

  const { register, handleSubmit, formState } = useForm<EidrMissingSeasonEpisodeForm>({
    defaultValues: {
      episodeNumber: '',
      episodeTitleName: '',
      episodeOriginalLanguage: '',
      episodeReleaseDate: '',
      episodeCountryOfOrigin: '',
      episodeApproximateLengthHours: '',
      episodeApproximateLengthMinutes: '',
      episodeApproximateLengthSeconds: ''
    }
  });

  const onSubmit: SubmitHandler<EidrMissingSeasonEpisodeForm> = (values) => {
    dispatch(setSourceCatalog(SourceCatalog.EIDR));

    if (props.mixMode) {
      const missingSeason: AddEidrSeason = {
        seriesEidrId: selectedRootEidr.ID,
        endDate: values.seasonEndDate,
        releaseDate: values.seasonReleaseDate,
        sequenceNumber: parseInt(values.seasonNumber) || ''
      };
      dispatch(setMissingSeason(missingSeason));
    }

    const hours = parseInt(values.episodeApproximateLengthHours);
    const minutes = parseInt(values.episodeApproximateLengthMinutes);
    const seconds = parseInt(values.episodeApproximateLengthSeconds);
    const approximateLength =
      (hours || minutes || seconds) &&
      Temporal.Duration.from({
        ...(hours && { hours: hours }),
        ...(minutes && { minutes: minutes }),
        ...(seconds && { seconds: seconds })
      }).toString();
    const missingEpisode: AddEidrEpisode = {
      title: values.episodeTitleName,
      originalLanguage: values.episodeOriginalLanguage,
      releaseDate: values.episodeReleaseDate,
      countryOfOrigin: values.episodeCountryOfOrigin,
      sequenceNumber: parseInt(values.episodeNumber) || '',
      approximateLength: approximateLength || ''
    };

    if (selectedSeasonEidr?.ID) missingEpisode.seasonEidrId = selectedSeasonEidr.ID;

    dispatch(setMissingEpisode(missingEpisode));
    const { AboveRecord } = selectedRootEidr;
    const { id: seriesAboveId, folder_location: seriesFolderLocation } = AboveRecord || {
      id: '',
      folder_location: ''
    };
    const candidate: TitleCandidate = {
      sourceCatalog: SourceCatalog.EIDR,
      mpmNumber:
        selectedEpisodeEidr && selectedEpisodeEidr.AlternateID
          ? eidrRecordMpmNumber(selectedEpisodeEidr)
          : '',
      eidrId: '',
      titleName: values.episodeTitleName,
      referentType:
        selectedRootEidr.ReferentType === 'Series'
          ? 'TV'
          : (selectedRootEidr.ReferentType.toUpperCase() as ReferentType),
      studio: '',
      editVersion: '',
      isEpisode: true,
      seriesMpmNumber:
        selectedRootEidr && selectedRootEidr.AlternateID
          ? eidrRecordMpmNumber(selectedRootEidr)
          : '',
      seriesAboveId: seriesAboveId,
      seriesFolderLocation: seriesFolderLocation,
      seriesEidrId: selectedRootEidr.ID,
      seriesName: selectedRootEidr.ResourceName.value,
      seriesReleaseDate: selectedRootEidr.ReleaseDate,
      seasonNumber:
        parseInt(selectedSeasonEidr?.ExtraObjectMetadata.SeasonInfo.SequenceNumber || '') ||
        parseInt(values.seasonNumber) ||
        '',
      episodeNumber: parseInt(values.episodeNumber) || ''
    };
    dispatch(setTitleCandidates([candidate]));
    dispatch(setSelectedCandidate(candidate));
    props.onClose();
  };

  return (
    <Modal
      title={props.mixMode ? 'Add A Missing Season & Episode' : 'Add A Missing Episode'}
      visible={props.open}
      setVisible={props.setOpen}
      onClose={props.onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {props.mixMode && (
          <div className="divide-y divide-gray-100">
            <FormItem key="seasonNumber" label="Season#">
              <Input
                className="w-full"
                rounded
                {...register('seasonNumber', { required: true })}
                error={formState.errors.seasonNumber?.message}
              />
            </FormItem>
            <FormItem key="seasonReleaseDate" label="Release date">
              <Input
                className="w-full"
                rounded
                {...register('seasonReleaseDate', { required: true })}
                error={formState.errors.seasonReleaseDate?.message}
              />
            </FormItem>
            <FormItem key="seasonEndDate" label="End year">
              <Input
                className="w-full"
                rounded
                {...register('seasonEndDate', { required: true })}
                error={formState.errors.seasonEndDate?.message}
              />
            </FormItem>
          </div>
        )}
        <div className="divide-y divide-gray-100">
          <FormItem key="episodeNumber" label="Episode#">
            <Input
              className="w-full"
              rounded
              {...register('episodeNumber', { required: true })}
              error={formState.errors.episodeNumber?.message}
            />
          </FormItem>
          <FormItem key="episodeReleaseDate" label="Release date">
            <Input
              className="w-full"
              rounded
              {...register('episodeReleaseDate', { required: true })}
              error={formState.errors.episodeReleaseDate?.message}
            />
          </FormItem>
          <FormItem key="episodeOriginalLanguage" label="Original language">
            <Dropdown
              className="w-full"
              placeholder="Please select language..."
              {...register('episodeOriginalLanguage', { required: true })}
              options={languages}
            />
          </FormItem>
          <FormItem key="episodeCountryOfOrigin" label="Country of Origin">
            <Dropdown
              className="w-full"
              placeholder="Please select country..."
              {...register('episodeCountryOfOrigin', { required: true })}
              options={territories}
            />
          </FormItem>
          <FormItem key="episodeApproximate" label="Approximate length" className="w-full">
            <div className="flex flex-col gap-2 w-3/6">
              <div className="flex flex-row gap-1">
                <Input
                  className="w-max-fit"
                  placeholder="HH"
                  rounded
                  {...register('episodeApproximateLengthHours')}
                  showErrorBorder={Boolean(formState.errors.episodeApproximateLengthHours)}
                />
                <Input
                  className="w-max-fit"
                  placeholder="MM"
                  rounded
                  {...register('episodeApproximateLengthMinutes')}
                  showErrorBorder={Boolean(formState.errors.episodeApproximateLengthMinutes)}
                />
                <Input
                  className="w-max-fit"
                  placeholder="SS"
                  rounded
                  {...register('episodeApproximateLengthSeconds')}
                  showErrorBorder={Boolean(formState.errors.episodeApproximateLengthSeconds)}
                />
              </div>
              <div className="text-danger text-sm flex flex-col gap-1">
                {formState.errors.episodeApproximateLengthHours && (
                  <div>{formState.errors.episodeApproximateLengthHours?.message}</div>
                )}
                {formState.errors.episodeApproximateLengthMinutes && (
                  <div>{formState.errors.episodeApproximateLengthMinutes?.message}</div>
                )}
                {formState.errors.episodeApproximateLengthSeconds && (
                  <div>{formState.errors.episodeApproximateLengthSeconds?.message}</div>
                )}
              </div>
            </div>
          </FormItem>
          <FormItem key="episodeTitleName" label="Episode name">
            <Input
              className="w-full"
              rounded
              {...register('episodeTitleName', { required: true })}
              error={formState.errors.episodeTitleName?.message}
            />
          </FormItem>
        </div>
        <div className="border-t border-gray-200 flex flex-row justify-end gap-2 p-4">
          <Button variant="primaryOutlined" rounded onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="primary" rounded type="submit" disabled={!formState.isValid}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
