import React, { forwardRef, Ref } from 'react';
import BellIcon from '../../assets/icons/bell.svg?react';
import BellOffIcon from '../../assets/icons/bell-off.svg?react';
import { useState } from 'react';
import {
  usePostWebPushSubscribeMutation,
  usePostWebPushUnsubscribeMutation
} from '../../features/webpush/webpushApi';
import { ingestionApi } from '../../features/ingestion/ingestionApi';
import { useAppDispatch } from '../../app/hooks';
import Switch from '../ui/Switch';

const vapidPublicKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const PushSubscription = forwardRef((props, forwardRef: Ref<HTMLButtonElement>) => {
  const [postWebPushSubscribe] = usePostWebPushSubscribeMutation();
  const [postWebPushUnsubscribe] = usePostWebPushUnsubscribeMutation();
  const [isSubscribed, setIsSubscribed] = useState(false);
  let serviceWorkerRegistration: ServiceWorkerRegistration;

  function subscribeUser() {
    if (Notification.permission === 'denied') {
      console.info('Push notifications are blocked');
      return;
    }
    const applicationServerKey = urlB64ToUint8Array(vapidPublicKey);
    serviceWorkerRegistration?.pushManager &&
      serviceWorkerRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        })
        .then((subscription) => {
          console.info('User is subscribed.');

          postWebPushSubscribe(subscription);
          setIsSubscribed(true);
        })
        .catch((err) => {
          console.error('Failed to subscribe the user: ', err);
        });
  }

  const unsubscribeUser = () =>
    serviceWorkerRegistration?.pushManager &&
    serviceWorkerRegistration.pushManager
      .getSubscription()
      .then((subscription) => {
        subscription
          ?.unsubscribe()
          .then(() => {
            postWebPushUnsubscribe(subscription);
            setIsSubscribed(false);
            console.info('User is unsubscribed.');
          })
          .catch((error) => {
            console.error('Error unsubscribing', error);
          });
      })
      .catch((error) => {
        console.error('Error retrieving subscription', error);
      });

  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker
      .register('/service-worker.js', { scope: '/' })
      .then((swReg) => {
        serviceWorkerRegistration = swReg;

        // Try to set the initial subscription value
        serviceWorkerRegistration?.pushManager &&
          serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
            setIsSubscribed(subscription !== null);
            if (Notification.permission === 'denied') {
              console.info('Push notifications are blocked. Unsubscribing the user');
              // there is no point in being subscribed without proper notification permissions
              unsubscribeUser();
            }
          });
      })
      .catch((error) => {
        console.error('Service worker error', error);
      });
  } else {
    console.warn('Web push is not supported');
  }
  const broadcast = new BroadcastChannel('update-channel');
  const dispatch = useAppDispatch();
  broadcast.onmessage = (event) => {
    if (event.data.ingestion_process_id) {
      dispatch(
        ingestionApi.util.invalidateTags([
          { type: 'IngestionProcess', id: event.data.ingestion_process_id }
        ])
      );
    }
  };

  return (
    <div className="flex items-center mx-4">
      <Switch
        {...props}
        ref={forwardRef}
        checked={isSubscribed}
        onCheckedChange={isSubscribed ? unsubscribeUser : subscribeUser}>
        {isSubscribed ? (
          <BellIcon className="w-5 h-5 p-0.5 stroke-primary-700" />
        ) : (
          <BellOffIcon className="w-5 h-5 p-0.5 stroke-gray-500" />
        )}
      </Switch>
    </div>
  );
});
PushSubscription.displayName = 'PushSubscription';
export default PushSubscription;
